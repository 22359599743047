angular
    .module('annexaApp')
    .component('annexaBoxOrgans',{
        templateUrl: './components/sec/annexa-box-organs/annexa-box-organs.html',
        controller:['globalModals', 'Language', 'AnnexaEntityFactory', 'ModalService', '$rootScope', 'AnnexaObjectBoxFactory', 'AnnexaFormlyFactory', 'GlobalDataFactory', '$filter', '$scope', function(globalModals, Language, AnnexaEntityFactory, ModalService, $rootScope, AnnexaObjectBoxFactory, AnnexaFormlyFactory, GlobalDataFactory, $filter, $scope) {
            var vm = this;
            
            this.$onInit = function () {
               
            	if(!vm.boxTitle) {
                    vm.boxTitle = 'global.sec.literals.organs';
                }
            }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            new: '=',
            content: '=',
            isEdit: '=',
            config: '=',
            origin: '@',
            canEdit: '='
        }
    })
    .component('annexaBoxOrgansRender', {
        templateUrl: './components/sec/annexa-box-organs/annexa-box-organs-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['SecFactory', '$filter', 'DialogsFactory', '$rootScope', 'Language', 'AnnexaFormlyFactory', '$scope', '$state', 'GlobalDataFactory', function(SecFactory, $filter, DialogsFactory, $rootScope, Language, AnnexaFormlyFactory, $scope, $state, GlobalDataFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.agreementOrgan = {};
            vm.allOpinionOrgans = ((GlobalDataFactory.organs && $linq(GlobalDataFactory.organs).count("x => x.resolutionType == 'OPINION'") > 0)? $linq(GlobalDataFactory.organs).where("x => x.resolutionType == 'OPINION'").toArray():[]);
            vm.allAgreementOrgans = ((GlobalDataFactory.organs && $linq(GlobalDataFactory.organs).count("x => x.resolutionType == 'AGREEMENT'") > 0)? $linq(GlobalDataFactory.organs).where("x => x.resolutionType == 'AGREEMENT'").toArray():[]);
            
            vm.addOpinionOrgan = function() {
            	var options = [];
            	_.forEach(vm.allOpinionOrgans, function(proposalOrgan){
            		var organs = $linq(vm.content).where("x => x.organ && x.organ.id == "+proposalOrgan.id).toArray();
            		if(organs && organs.length > 0){
            			_.forEach(organs, function(organ){
            				if(organ.session && organ.session.executedOrder && $linq(options).count("x => x.id == "+organ.organ.id) == 0){
            					var orders = $linq(organ.session.executedOrder).where("x => x.proposal && x.proposal.id == "+organ.proposal.id).toArray();
            					if(orders){
            						var allRefused = true;
            						_.forEach(orders, function(order){
            							if(order.voteState != 'REFUSED' && order.voteState != 'POSTPONED'){
            								allRefused = false;
            							}
            						});
            						if(alllRefused == true){
            							options.push(proposalOrgan);
            						}
            					}
            				}
            			})
            		}else{
            			options.push(proposalOrgan);
            		}
            	});
            	if(options.length > 0){
	                var newModal = {
	                    title: 'global.sec.literals.addOpinionOrgan',
	                    size: '',
	                    fieldsDefinition: [
	                    	{ type: 'field', id: 'organ', fieldType: 'select', data: options, colClass: 'col-sm-12', required: true, label: 'global.sec.literals.organ' }
	                    ],
	                    alerts: [],
	                    submitModal: function() {
	                    	this.form.$setSubmitted();
	                        if(this.form.$valid) {
	                        	if(vm.isEdit){
	                          		$rootScope.$broadcast('annexaBoxOrgansAddOpinionOrgan', { organ: this.model.organ, origin: vm.origin, modal: newModal});	
	                        	}
	                        }
	                    },
	                    hideSubmit: false,
	                    closeLabel: 'global.literals.close'
	                }
	
	                AnnexaFormlyFactory.showAnnexaFormModal('modalAddOpinionOrgan', newModal);
            	}else{
            		DialogsFactory.notify('global.sec.literals.noOpinionOrganToAdd');
            	}
            }
            vm.selectOpinionOrgan = function(organ, index) {
            	if(vm.isEdit) {
                    $rootScope.$broadcast('annexaBoxOrgansModifyOpinionOrgan', { organ: organ, index:index, origin: vm.origin });
                }
            }
			vm.seeSession = function(organ) {
	                if(organ && organ.session && organ.session.id){
	                	window.open($state.href('annexa.sec.sessions.view', { session: organ.session.id }), '_blank');
	                }else if(vm.agreementOrgan && vm.agreementOrgan.session && vm.agreementOrgan.session.id){
	                	window.open($state.href('annexa.sec.sessions.view', { session: vm.agreementOrgan.session.id }), '_blank');
	                }
			}
			vm.selectAgreementOrgan = function() {
				if(vm.isEdit) {
                    $rootScope.$broadcast('annexaBoxOrgansModifyAgreementOrgan', { organ: vm.agreementOrgan, origin: vm.origin});
                }
			}
            
            vm.delete = function(organ, index) {
                DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteOpinionOrganBox')
                    .then(function (data) {
                        var removedIds = [];
                        removedIds.push(organ.id);
                        if(vm.isEdit) {
                            $rootScope.$broadcast('annexaBoxOrgansDelete', { removedIds: removedIds, index:index, origin: vm.origin });
                        }
                    }).catch(function (data) {
                        //Empty
                });
            }
            
            vm.getIconClassStatus = function (status) {
                if(status){
                    if(status == 'PENDING' || status == 'CONVENED' || status == 'STARTED'){
                        return 'blue';
                    }else if(status == 'EXECUTED' || status == 'FINISHED'){
                        return 'success';
                    }else if(status == 'CANCELED'){
                        return 'danger';
                    } else {
                        return'text-grey';
                    }
                }else{
                    return 'text-grey';
                }
            }
            
            vm.getNameStatus = function (status) {
                if(status){
                    return $filter('translate')('global.sec.literals.' + status);
                }else{
                    return '';
                }                
            }
            
            this.$onInit = function () {
            	if(vm.content){
                	_.forEach(vm.content, function(organ){
                		if(organ.session && organ.session.id){
                    		organ.sessionInformation = organ.organ[vm.languageColumn]+"  -  "+$filter('translate')('global.sec.literals.session')+" "+organ.session.sessionType[vm.languageColumn]+" "+$filter('translate')('global.profile.from')+" "+$filter('date')(new Date(organ.session.sessionDateFirstConvene), 'dd/MM/yyyy HH:mm');
                    	}
                    });
                	var agreementOrgans = $linq(vm.content).where("x => x.organ.resolutionType == 'AGREEMENT'").toArray();
                	if(agreementOrgans && agreementOrgans.length > 0){
                		vm.agreementOrgan = agreementOrgans[0];
                	}
                }
            }

        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            origin: '@',
            canEdit: '='
        }
    })
angular
    .module('annexaApp')
    .component('annexaBoxObjectProposal',{
        templateUrl: './components/sec/annexa-box-object-proposal/annexa-box-object-proposal.html',
        controller: ['SecFactory', '$filter', '$rootScope', 'ErrorFactory', '$state', '$scope', 'RestService', 'HelperService', 'AnnexaPermissionsFactory', 'GlobalDataFactory', 'Language', 'AnnexaFormlyFactory', 'CommonService', 'DialogsFactory', 'HeaderService', 'SecModals', 'DccumentsFactory', '$http', 'TramFactory', function (SecFactory, $filter, $rootScope, ErrorFactory, $state, $scope, RestService, HelperService, AnnexaPermissionsFactory, GlobalDataFactory, Language, AnnexaFormlyFactory, CommonService, DialogsFactory, HeaderService, SecModals, DccumentsFactory, $http, TramFactory) {
            //region General 
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();
            vm.loggedUser = $rootScope.LoggedUser;
            vm.canEdit = ((vm.proposal && vm.proposal.state && _.contains(['PENDING', 'ACCEPTED'],vm.proposal.state))?true:false);
            if(vm.canEdit && vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE' && vm.proposal.state == 'ACCEPTED'){
            	vm.canEdit = false;
            }
            //region Form

            vm.alerts = [];

            //endregion

            //region Organs Box
            $scope.$on('annexaBoxOrgansAddOpinionOrgan', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-proposal' && vm.isEdit) {
                	if(args.organ && args.organ && args.modal){
                		SecFactory.addProposalOrgan(vm.proposal.id, args.organ).then(function(data) {
	                        if(data) {
	                        	 vm.organsBox.content.push(data);
	                        }
	                        args.modal.close();
	                    }).catch(function(error) {
	                    	args.modal.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'addOrgan', error)});
	                    })
	                }else{
	            		DialogsFactory.error('global.sec.literals.error_required_files_organ');
	            	}
                }
            });
            
    		$scope.$on('annexaBoxOrgansModifyOpinionOrgan', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-proposal' && vm.isEdit) {
                	if(args.organ && args.organ.id && args.organ.organ && args.organ.organ.id){
	                	SecFactory.updateProposalOrgan(vm.proposal.id, args.organ.id, args.organ.organ.id).then(function(data) {
	                		if(data) {
		                		var index = $linq(vm.organsBox.content).indexOf("x => x.id == " + data.id);
		                        if(index != -1) {
		                            vm.organsBox.content[index] = data;
		                        }
	                		}
	                    }).catch(function(error) {
	                    	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'modifyOrgan', error)});
	                    })
                	}else{
                		DialogsFactory.error('global.sec.literals.error_required_files_organ');
                	}
                }
            });
			
    		$scope.$on('annexaBoxOrgansModifyAgreementOrgan', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-proposal' && vm.isEdit) {
                	if(args.organ && args.organ.id && args.organ.organ && args.organ.organ.id){
	                	SecFactory.updateProposalOrgan(vm.proposal.id, args.organ.id, args.organ.organ.id).then(function(data) {
		                	if(data){
		                		var index = $linq(vm.organsBox.content).indexOf("x => x.id == " + data.id);
			                    if(index != -1) {
			                        vm.organsBox.content[index] = data;
			                    }
		                	}
	                    }).catch(function(error) {
	                    	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'modifyOrgan', error)});
	                    })
                	}else{
                		DialogsFactory.error('global.sec.literals.error_required_files_organ');
                	}
                }
            });
			
    		$scope.$on('annexaBoxOrgansDelete', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-proposal' && vm.isEdit) {
                	if(args.removedIds && args.removedIds.length > 0){
	                	SecFactory.deleteProposalOrgan(vm.proposal.id, args.removedIds).then(function(data) {
	                        if(data) {
	                        	_.forEach(args.removedIds, function(id){
	                        		var index = $linq(vm.organsBox.content).indexOf("x => x.id == " + id);
	                                if(index != -1) {
	                                    vm.organsBox.content.splice(index,1);
	                                }
	                        	});
	                        	
	                        }
	                    }).catch(function(error) {
	                    	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'deleteOrgan', error)});
	                    })
                	}else{
                		DialogsFactory.error('global.sec.literals.error_required_files_removedIds');
                	}
                }
            });
    		
    		$scope.$on('annexaBoxUpdateSaveProposal', function (event, args) {
	            if(args.origin && args.origin == 'annexa-box-object-proposal') {
	            	vm.canEdit = ((vm.proposal && vm.proposal.state && _.contains(['PENDING', 'ACCEPTED'],vm.proposal.state))?true:false);
	            	if(vm.canEdit && vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE' && vm.proposal.state == 'ACCEPTED'){
	                	vm.canEdit = false;
	                }
	                HeaderService.changeState($state.current, true);
	                $state.reload();
	            }
	        });

   	        //endregion

    		//region Internal Communication Box
            $scope.$on('annexaBoxInternalCommunicationAdd', function (event, args) {
            	if(args.origin && args.origin == ('annexa-box-object-proposal')) {
            		vm.updateProposal(args.internalCommunication, 'internalCommunications');
             	}
             });
             //endregion

            //region General Box

            var getEditPermissions = function(type) {
                switch (type) {
                    default:
                        var permissions = [];

                        if(AnnexaPermissionsFactory.havePermission(vm.editPerm)) {
                            permissions.push(vm.editPerm);
                        }

                        if(AnnexaPermissionsFactory.havePermission(vm.newPerm)) {
                            permissions.push(vm.newPerm);
                        }

                        return permissions;
                        break;
                }
            }

            //endregion 
            

            this.$onInit = function() {

            	var actualOrgan = vm.getActualProposalOrgan();
                
            	vm.notificatorProfiles = vm.getNotificatorProfiles();
            	
            	if ($rootScope.esetMode && vm.notificatorProfiles) vm.notificatorResponsibles = vm.getNotificatorResponsibles(vm.notificatorProfiles[0]);
            	
            	if(vm.proposal && vm.proposal.footerClaims){
            		vm.proposal.footerClaimsAux = $linq(vm.proposal.footerClaims).select("x => x.footerClaim").toArray();
            	}
            	
            	// Aqui nos creamos el formulario para una propuesta.
                vm.proposalFormFields = [];
                vm.proposalFormFields.push({ type: 'field', id: 'proposalType', fieldType: 'select', data: GlobalDataFactory.proposalTypes, colClass: 'col-sm-12', required: true, label: 'global.sec.literals.proposalType', isReadOnly:vm.canEdit,
                		printFunction:  function(proposalType) {
                            if(proposalType) {
                            	return proposalType[vm.languageColumn];
                            } else {
                                return '';
                            }
                        }
                	});
                vm.proposalFormFields.push({ type: 'field', id: 'profile', fieldType: 'select', data: [], colClass: 'col-sm-12', required: true, label: 'global.sec.literals.created_profile', isReadOnly:false  });
                if(vm.proposal && (!vm.proposal.proposalType || !vm.proposal.proposalType.proposalTypeSubtype || vm.proposal.proposalType.proposalTypeSubtype != 'DECREE')){
	                vm.proposalFormFields.push({ type: 'field', id: 'notificatorProfile', fieldType: 'select', data: vm.notificatorProfiles, colClass: 'col-sm-12', required: true, label: 'global.sec.literals.notificator_profile', isReadOnly:vm.canEdit, 
	                		editFunction: $rootScope.esetMode ? vm.popupNotificatorResponsibles : undefined, 
	                		printFunction:  function(notificatorProfile) {
	                			if(notificatorProfile) {
	                				return notificatorProfile[vm.languageColumn];
	                            } else {
	                            	return '';
	                            }
	                        }                	
	                	});
	                vm.proposalFormFields.push({ type: 'field', id: 'notificatorResponsible', fieldType: 'select', data: vm.notificatorResponsibles, labelProp: 'completeName', hideExpression: !$rootScope.esetMode, showIf: function(){return $rootScope.esetMode;}, colClass: 'col-sm-12', required: true, label: 'global.sec.literals.notificator_responsible', isReadOnly:vm.canEdit,
	                		printFunction:  function(notificatorResponsible) {
	                			if(notificatorResponsible) {
	                				return notificatorResponsible.completeName;
	                            } else {
	                            	return '';
	                            }
	                        }                	
	                	});
                }
                vm.proposalFormFields.push({ type: 'field', id: 'anonymousThirds', fieldType: 'select', data: [ { id: true, name: 'global.literals.yes' }, { id: false, name: 'global.literals.no' }], labelProp: 'name', colClass: 'col-sm-12', required: true, isReadOnly:vm.canEdit, label: 'global.sec.literals.anonymReceiver',
                		printFunction:  function(anonymousThirds) {
                            if(anonymousThirds) {
                                return $filter('translate')('global.literals.yes');
                            } else {
                                return $filter('translate')('global.literals.no');
                            }
                        }
                	});
                vm.proposalFormFields.push({ type: 'field', id: 'dateLimit', fieldType: 'date', colClass: 'col-sm-12', required: false, label: 'global.literals.deadlineDate', isReadOnly:false });
                vm.proposalFormFields.push({ type: 'field', id: 'footerClaimsAux', fieldType: 'select_multiple', colClass: 'col-sm-12 daughter-label-strong', data: GlobalDataFactory.footerClaims, label: 'global.sec.literals.footerClaims', isReadOnly:vm.canEdit,
                		printFunction:  function(footerClaims) {
                			var content = '';
                			if(footerClaims) {
                                _.forEach(footerClaims, function(footerClaim){
                                	content += ((content == '')?'':', ')+footerClaim[vm.languageColumn];
                                });
                            }
                			return content;
                        }
                	});
                vm.proposalFormFields.push({ type: 'field', id: 'managementCenterObservations', fieldType: 'textarea', colClass: 'col-sm-12', required: false, label: 'global.sec.literals.observations_management_center', isReadOnly:false });
                vm.proposalFormFields.push({ type: 'field', id: 'secretariatObservations', fieldType: 'textarea', colClass: 'col-sm-12', required: false, label: 'global.sec.literals.observations_secretaria', isReadOnly:vm.canEdit });
                       
            	vm.newPerm = '';
            	vm.editPerm = '';
            	vm.isEdit = true;
            	
            	
                
                //region Init Boxs

                vm.organsBox = {
                    content: ((vm.proposal.organs)? vm.proposal.organs:[]),
                    new: {},
                    config: {},
                    origin: 'annexa-box-object-proposal'
                }
            
                $rootScope.$broadcast('dummy');

                vm.internalCommunicationBox = {
            		content: ((vm.proposal) ? vm.proposal:[]),
                    new: {},
                    config: {},
                    origin: 'annexa-box-object-proposal'
                }

                vm.documentBox = {
                    content: ((vm.proposal.documents)? $linq(vm.proposal.documents).toArray() : []),
                    config: { documentTitle: undefined },
                    origin: 'annexa-box-object-proposal'
                }


                vm.dossierBox = {
                    content: ((vm.proposal.dossiers)? $linq(vm.proposal.dossiers).select("x => x.dossier").toArray() : []),
                    config: {},
                    origin: 'annexa-box-object-proposal'
                }

                vm.decreeBox = {
                    content: ((SecFactory.decrees)? SecFactory.decrees : []),
                    config: {},
                    origin: 'annexa-box-object-proposal'
                }
				vm.operationsActive = (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.active)?true:false);
				if(vm.operationsActive && vm.proposal && vm.proposal.createdTransaction && vm.proposal.createdTransaction.dossierId && vm.proposal.dossiers){
					var dossierCreated = $linq(vm.proposal.dossiers).firstOrDefault(undefined, "x=>x.dossier && x.dossier.id == "+vm.proposal.createdTransaction.dossierId);
					if(dossierCreated && dossierCreated.dossier && dossierCreated.dossier.procedure && dossierCreated.dossier.procedure.operationsActive){
						vm.operationsActive = true;
					}else{
						vm.operationsActive = false;
					}
				}else{
					vm.operationsActive = false;
				}
    			vm.userAccountInstances = TramFactory.userAccountInstances;
        		var proposalOperationsAndInvoices = [];
				if(vm.proposal && vm.proposal.operations && vm.proposal.operations.length > 0){
					proposalOperationsAndInvoices = $linq(vm.proposal.operations).select("x=>x.operation").toArray();
				}
	 			vm.proposalOperationsAndInvoicesBox = {
	            	boxTitle: 'global.operationsAndInvoices.title',
	                permissions: ((vm.userAccountInstances && vm.userAccountInstances.length > 0 && vm.isEdit)?{new:'process_dossier'}:{}),
	                content:  proposalOperationsAndInvoices,
	                config: { proposal: vm.proposal },
					onlyAddOperations: true,
					canRemoveOperations: (vm.userAccountInstances && vm.userAccountInstances.length > 0 && vm.isEdit),
					newTitle: 'global.literals.add',
	                origin: 'tram'
	            };
                //endregion
            }

            //region proposal Methods

            vm.aproveProposal = function() {
            	if(vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE'){
            		DialogsFactory.confirm('global.sec.literals.createDecreeMessage', 'global.sec.literals.createDecreeMessageConfirm').then(function (data) {
						var archiveClassificationId = undefined;
						var dossiersAuxprop = ((vm.proposal && vm.proposal.createdTransaction && vm.proposal.createdTransaction.id && vm.proposal.dossiers)?vm.proposal.dossiers:undefined);
						if(dossiersAuxprop && dossiersAuxprop.length > 0){
							_.forEach(dossiersAuxprop, function(dos){
								if(!archiveClassificationId && dos.dossier && dos.dossier.dossierTransactions && dos.dossier.dossierTransactions.length > 0){
									var dt = $linq(dos.dossier.dossierTransactions).firstOrDefault(undefined,"x => x.id =="+ vm.proposal.createdTransaction.id);
									if(dt){
										archiveClassificationId = ((dos.dossier && dos.dossier.archiveClassification && dos.dossier.archiveClassification.id)?dos.dossier.archiveClassification.id:undefined);
									}
								}
							});
						}
						//((vm.proposal && vm.proposal.createdTransaction && vm.proposal.createdTransaction.dossier && vm.proposal.createdTransaction.dossier.archiveClassification)?vm.proposal.createdTransaction.dossier.archiveClassification.id:undefined);
                		SecFactory.createDocumentModal('SDR', null, 'proposal', vm.proposal, true, undefined, undefined, archiveClassificationId)
                        .then(function(data) {
                            var selfData = data;
                            $http({
                                url: './api/sec/proposal/' + vm.proposal.id + '/decreee',
                                method: 'POST', 
                                data: JSOG.encode(selfData.request)
                            }).then(function(data) {
                            	selfData.modal.close();
                            	$rootScope.$broadcast('annexaBoxUpdateSaveProposal', {origin: 'annexa-box-object-proposal'});
    	                        $state.transitionTo('annexa.sec.proposals');
                            }).catch(function (error) {
                            	if(error && error.data && error.data.code && error.data.code.toUpperCase() == 'Template Exception'.toUpperCase()){
									var msg = $filter('translate')('global.tram.errors.processTemplate')+((error.data.message)?'<br/><br/>'+error.data.message:'');
									DialogsFactory.error(msg, $filter('translate')('DIALOGS_ERROR_MSG'));
								}else{
				                	DialogsFactory.error($filter('translate')('global.sec.errors.cantGenerateDecree'));
								}
                            	selfData.modal.close();
                            });
                        }).catch(function(error) {
                            //Empty;
                        })
                   }).catch(function (data) {
        	               //Empty
        	       });
            	}else{
            		SecFactory.updateStateProposal(vm.proposal.id, 'ACCEPTED').then(function(data) {
	                    if(data) {
	                        vm.proposal.state = data.state;
	                        $rootScope.$broadcast('annexaBoxUpdateSaveProposal', {origin: 'annexa-box-object-proposal'});
	                        $state.transitionTo('annexa.sec.proposals');
	                    }
	                }).catch(function(error) {
	                	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateProposal', error)});
	                })
            	}
            };
            
            vm.generateAmendment = function() {
                var modal = angular.copy(SecModals.amendmentProposal);
                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};

                var confirmGenerateAmendment = function () {
                    if (this.annexaFormly.model.modal_body.reason) {
                    	var secretariatObservations = this.annexaFormly.model.modal_body.reason;
                    	DialogsFactory.confirm('global.sec.literals.amendmentPopupProposal', 'global.sec.literals.confirmAmendmentProposal').then(function (dataAux) {
                        	SecFactory.updateStateProposal(vm.proposal.id, 'AMENDMENT', secretariatObservations).then(function(data) {
                                if(data) {
                                	vm.proposal.state = data.state;
                                	$rootScope.$broadcast('annexaBoxUpdateSaveProposal', {origin: 'annexa-box-object-proposal'});
                                	$state.transitionTo('annexa.sec.proposals');
                                }
                            }).catch(function(error) {
                            	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateProposal', error)});
                            })
                        }).catch(function (data) {
                        	//Empty
                        });        	
                        modal.close();
                    }
                };

                AnnexaFormlyFactory.showModal("modalAmendmentProposal", modal, confirmGenerateAmendment, false);
            };
            
            vm.cancelProposal = function() {
                var modal = angular.copy(SecModals.cancelProposal);
                modal.alerts = [];
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};

                var confirmCancelProposal = function () {
                    if (this.annexaFormly.model.modal_body.reason) {
                    	var secretariatObservations = this.annexaFormly.model.modal_body.reason;
                    	DialogsFactory.confirm('global.sec.literals.cancelPopupProposal', 'global.sec.literals.confirmCancelProposal').then(function (dataAux) {
                        	SecFactory.updateStateProposal(vm.proposal.id, 'CANCELED', secretariatObservations).then(function(data) {
                                if(data) {
                                	vm.proposal.state = data.state;
                                	$rootScope.$broadcast('annexaBoxUpdateSaveProposal', {origin: 'annexa-box-object-proposal'});
                                	$state.transitionTo('annexa.sec.proposals');
                                }
                            }).catch(function(error) {
                            	vm.alerts.push({msg: ErrorFactory.getErrorMessage('sec', 'updateStateProposal', error)});
                            })
                        }).catch(function (data) {
                        	//Empty
                        });        	
                        modal.close();
                    }
                };

                AnnexaFormlyFactory.showModal("modalCancelProposal", modal, confirmCancelProposal, false);
            };
            
            vm.editDecreeDocument = function(){
            	if(vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE' && vm.proposal.decreeDocument){
    			   	DccumentsFactory.getEditOnlineUrl(vm.proposal.decreeDocument).then(function(data) {
                        window.location.href = data;
                    }).catch(function(error) {
                    	DialogsFactory.error('global.sec.literals.noDecreeDocument', 'Error');
                    });
            	}else{
            		DialogsFactory.error('global.sec.literals.noDecreeDocument', 'Error');
            	}
            }
            
            
            $scope.goBack = function(){
                $state.transitionTo('annexa.sec.proposals');
            };
            

        	vm.popupNotificatorResponsibles = function () {
                var dataModal = {
                    row: true,
                    colClass: 'col-sm-12',
                    labelClass: ''
                };
                var modal = {
                    title: 'global.tram.literals.selectResponsible',
                    size: 'modal-sm',
                    icon: '',
                    submitModal: function () {
                    },
                    alerts: []		
                }
                modal.annexaFormly = new AnnexaFormly();
                
                var saveProfilesAndResponsibles = function(){
                	if (modal.annexaFormly.model.modal_body.profile && modal.annexaFormly.model.modal_body.selectedResponsible) {
                		vm.proposal.notificatorProfile = modal.annexaFormly.model.modal_body.profile;
                		vm.proposal.notificatorResponsible = modal.annexaFormly.model.modal_body.selectedResponsible;
                		
                        RestService.update('./api/sec/proposal/' + vm.proposal.id, vm.proposal).then(function (data) {
                        	vm.proposal.proposalNumber = data.proposalNumber;
                        	if (vm.proposal.notificatorResponsible) vm.proposal.notificatorResponsible.completeName = vm.proposal.notificatorResponsible.name + ' ' + vm.proposal.notificatorResponsible.surename1 + ((vm.proposal.notificatorResponsible.surename2) ? ' ' + vm.proposal.notificatorResponsible.surename2 + '\n' : '\n');
                        	vm.getNotificatorResponsibles(vm.proposal.notificatorProfile);
                        	modal.close();
                        }).catch(function (error) {
                            console.error(error);
                        });
                	} else {
                		modal.alerts.push({msg: 'global.tram.errors.noProfileNoResponsibleSelected'});
                	} 
                }
                
                var fields = [];
                fields.push(modal.annexaFormly.createField('profile','annexaSelectResponsibleRow', '', new AnnexaFormlyFieldSelectTemplateOptions('global.tram.literals.selectProfileAndResponsible','id', vm.languageColumn, vm.notificatorProfiles, true), dataModal));
                fields[0].templateOptions.profileId = vm.proposal.notificatorProfile ? vm.proposal.notificatorProfile.id : undefined;
                fields[0].templateOptions.responsibleId = vm.proposal.notificatorResponsible ? vm.proposal.notificatorResponsible.id : undefined;
                modal.annexaFormly.addGroup('modal_body', 'modal-body p-lg', fields);
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                AnnexaFormlyFactory.showModalPromise("updateNotificatorResponsible", modal, saveProfilesAndResponsibles, false, false, 'global.literals.select').then(function (modalAux) {
                    modal.modal = modalAux;
                    modal.close = modalAux.element.scope().close;
                    modalAux.element.modal();
                    angular.element(".modal-title").focus();
                }).catch(function () {
                    var a = 0;
                });
            
            }
        	
        	vm.updateProposal = function(val, prop) {
            	if(prop == 'anonymousThirds') {
                    vm.proposal[prop] = val.id;
            	} else if(prop == 'notificatorProfile' || prop == 'notificatorResponsible') {
            		vm.proposal[prop] = ((val && val.id)?{id:val.id}:undefined);
            	} else if(prop == 'footerClaimsAux') {
            		 var footerClaims = [];
            		 _.forEach(val, function(fc){
            			 var fcAct = $linq(vm.proposal.footerClaims).where("x => x.footerClaim.id == "+fc.id).toArray();
            			 if(fcAct && fcAct.length > 0){
            				 footerClaims.push(fcAct[0]);
            			 }else{
            				 footerClaims.push({proposal:{id:vm.proposal.id}, footerClaim:{id:fc.id}});
            			 }
            		 });
            		 vm.proposal.footerClaims = footerClaims;
             	} else if(prop == 'internalCommunications') {
             		vm.proposal[prop] = [];
            		if(val) {
                		_.forEach(val,function(profile){
                			vm.proposal[prop].push({profile:{id:profile.id}});
                		});                			
            		}
                } else {
                    vm.proposal[prop] = val;
                }

                RestService.update('./api/sec/proposal/' + vm.proposal.id, vm.proposal)
                     .then(function (data) {
                    	vm.proposal.proposalNumber = data.proposalNumber;
                    	if(prop == 'notificatorProfile') {
                    		vm.proposal[prop] = val;
                    	} else if (prop == 'notificatorResponsible') {
                    		vm.proposal[prop] = val;
                    		vm.getNotificatorResponsibles(data.notificatorProfile);
                    	}
                    	$rootScope.$broadcast('annexaBoxUpdateSaveProposal', {origin: 'annexa-box-object-proposal'});
                     }).catch(function (error) {
                         console.error(error);
                });
            }
            
            vm.getActualProposalOrgan = function(){
                var organ = null;

                if(vm.proposal && vm.proposal.organs){
                    _.forEach(vm.proposal.organs, function(value){
                        if(value.actual){
                            organ = value;
                        }
                    });
                }
                return organ;
            }
            
        	vm.getNotificatorProfiles = function(){
            	vm.notificatorProfiles = [];
            	var notificatorProfilesTransaction = [];
            	var proposalDossierTransactionProp = SecFactory.proposalDossierTransaction;
            	if(proposalDossierTransactionProp && proposalDossierTransactionProp[0].dossierTransaction && proposalDossierTransactionProp[0].dossierTransaction.processProfiles){
            		notificatorProfilesTransaction = $linq(proposalDossierTransactionProp[0].dossierTransaction.processProfiles).select("x => x.profile").toArray();
                }
            	
            	var secretary = GlobalDataFactory.secretaryProfile;
            	if(!secretary){
            		secretary = $linq(GlobalDataFactory.allProfiles).singleOrDefault(undefined, "x => x.id == " + $rootScope.app.configuration.secretary_properties.secretary_profile);
            	}
            	if(notificatorProfilesTransaction) {
            		_.forEach(notificatorProfilesTransaction, function(notificatorProfileTransaction){
            			if(notificatorProfileTransaction && secretary 
            					&& notificatorProfileTransaction.id != secretary.id){
            				vm.notificatorProfiles.push(notificatorProfileTransaction);
                        }
                    });
            		if(secretary){
            			vm.notificatorProfiles.push(secretary);
            		}
            	}
            	
            	return vm.notificatorProfiles;
        	}
        	
        	vm.getNotificatorResponsibles = function(notificatorProfile){
            	vm.notificatorResponsibles = [];
            	
                if (notificatorProfile && notificatorProfile.userProfiles) {
                    angular.forEach(notificatorProfile.userProfiles, function (userProfile) {
                    	if (userProfile && userProfile.user && userProfile.user.name) {
                    		userProfile.user.completeName = userProfile.user.name + ' ' + userProfile.user.surename1 + ((userProfile.user.surename2) ? ' ' + userProfile.user.surename2 + '\n' : '\n');
                    		vm.notificatorResponsibles.push(userProfile.user);
                    	}
                    });
                }	
            	
            	return vm.notificatorResponsibles;
        	}
            //endregion
            
        }],
        bindings: {
            redirectToList: '=',
            proposal: '=?',
            preloadBoxes: '=?'
        }
    })
    .component('annexaBoxObjectProposalHeader',{
        templateUrl: './components/sec/annexa-box-object-proposal/annexa-box-object-proposal-header.html',
        require: {
            proposalComponent: '^^annexaBoxObjectProposal'
        },
        controller:['$rootScope', '$filter', 'Language', 'SecFactory', '$scope', function($rootScope, $filter, Language, SecFactory, $scope) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            
            $scope.$on('annexaBoxUpdateSaveProposal', function (event, args) {
                if(args.origin && args.origin == 'annexa-box-object-proposal') {
                	var state = $linq(SecFactory.proposalStates).singleOrDefault(undefined, "x => x.id == 'PENDING'");
                	if(vm.proposalComponent.proposal && vm.proposalComponent.proposal.state){
                		state = $linq(SecFactory.proposalStates).singleOrDefault(undefined, "x => x.id == '"+vm.proposalComponent.proposal.state+"'");
                	}
                	vm.state = state;
                	vm.canEdit = ((vm.proposalComponent.proposal && vm.proposalComponent.proposal.state && _.contains(['PENDING', 'ACCEPTED'],vm.proposalComponent.proposal.state))?true:false);
                	if(vm.canEdit && vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE' && vm.proposal.state == 'ACCEPTED'){
                    	vm.canEdit = false;
                    }
                    
                }
            });
            
            this.$onInit = function() {
            	vm.state = $linq(SecFactory.proposalStates).singleOrDefault(undefined, "x => x.id == 'PENDING'");
            	if(vm.proposalComponent.proposal && vm.proposalComponent.proposal.state){
            		vm.state = $linq(SecFactory.proposalStates).singleOrDefault(undefined, "x => x.id == '"+vm.proposalComponent.proposal.state+"'");
           		}
            	vm.canEdit = ((vm.proposalComponent.proposal && vm.proposalComponent.proposal.state && _.contains(['PENDING', 'ACCEPTED'],vm.proposalComponent.proposal.state))?true:false);
            	if(vm.canEdit && vm.proposal && vm.proposal.proposalType && vm.proposal.proposalType.proposalTypeSubtype == 'DECREE' && vm.proposal.state == 'ACCEPTED'){
                	vm.canEdit = false;
                }
                
            }
        }]
    })